import "common/components/custom-range-slider";
import { h } from "common/dom-builder";
import { smallIconButton } from "common/icon-buttons";
import { preferences } from "../preferences";

export class FontSizeSelector {
  parent;
  element;

  constructor(props) {
    this.element = props.element ?? props.panel?.panelElement;
    this.props = props;
    this.init();
  }

  static buildElementForHeader(typeTester) {
    if (!typeTester.isFormattable) return;

    if (preferences.showFontSizePanelInHeader) {
      const element = h(
        "div",
        {
          class: ["input-group", "size-input-group", "in-header"],
          "data-input-group": "size",
        },
        []
      );
      new FontSizeSelector({ typeTester, element });
      return element;
    } else {
      return h(
        "div",
        {
          class: [
            "input-group",
            "size-input-group",
            "expandable",
            // "expand-with-pushdown", // feature panel is the only one pushing down
          ],
          "data-input-group": "size",
        },
        [
          h("span", { class: "label" }, "Size"),
          h("span", { class: "icon-button expand-button" }),
        ]
      );
    }
  }

  get initialFontSize() {
    return this.props.typeTester.props.format.fontSize;
  }

  init() {
    this.render();
  }

  render() {
    this.element.appendChild(
      h("div", { class: ["with-icon-in-front", "with-value-indicator"] }, [
        smallIconButton("size"),
        this.buildInput(),
        this.renderValueIndicator(),
      ])
    );
    this.handleFontSizeChange(this.initialFontSize);
  }

  handleFontSizeChange(size) {
    this.props.typeTester.textEditor.setFontSize(size);
    this.valueIndicatorElement.textContent = size;
  }

  buildInput() {
    const inputElement = h("custom-range-slider", {
      min: 12,
      max: 600,
      step: 1,
      value: this.initialFontSize,
    });
    inputElement.addEventListener("input", (ev) => {
      this.handleFontSizeChange(ev.target.value);
    });
    inputElement.addEventListener("change", (ev) => {
      this.handleFontSizeChange(ev.target.value);
      this.props.typeTester.element.dispatchEvent(
        new CustomEvent("interaction")
      );
    });
    this.props.typeTester.addEventListener("reset", () => {
      inputElement.value = this.initialFontSize;
    });
    return inputElement;
  }

  renderValueIndicator() {
    return (this.valueIndicatorElement = h(
      "span",
      { class: ["value-indicator", "tabular-numbers"] },
      [`${this.initialFontSize}`]
    ));
  }
}
