import debug from "debug";
import Quill from "quill";

const { StyleAttributor, Scope } = Quill.import("parchment");

// Create a style attributor for font-feature-settings

// Define inline Quill attributors, so that we format any portion of the text like this:
// E.g. `quill.format('font-feature-settings', '"ss01" on')`
["font-family", "font-feature-settings", "letter-spacing"].forEach(
  (property) => {
    Quill.register(
      new StyleAttributor(property, property, {
        whitelist: null,
        scope: Scope.INLINE,
      })
    );
  }
);

// Define block-level Quill attributors.
// Note that font-size must be block-level, since the browser evaluates line-height relative to the font-size. If the line-height is set on the paragraph and font-size in the inner span, then the font-size is not taken into account when calculating the line-height.
["font-size", "line-height"].forEach((property) => {
  Quill.register(
    new StyleAttributor(property, property, {
      whitelist: null,
      scope: Scope.BLOCK,
    })
  );
});

/**
 * A representation of the editor, wrapper around Quill.
 *
 * Events:
 *   - format-change - detail: { features: { ss01: true, ss02: false }, format: { font-feature-settings: '"ss01" on, "ss02" off' }
 *   - focus-change - detail: { hasFocus: true }
 */
export class TextEditor {
  quill;
  element; // .rendered_text (contains .ql-editor which is contenteditable)
  hasFocus = false;
  lastValidSelection;
  debug = debug("type-tester:TextEditor"); // localStorage.debug = "type-tester:TextEditor"

  originalParentStyles = {};
  styleKeys = ["fontFamily", "fontSize", "lineHeight", "textAlign"];

  constructor(element, options = {}) {
    this.element = element;
    this.options = options;

    // Initialize Quill
    this.init();

    this.buildQuill();
  }

  init() {
    this.originalInnerHtml = this.element.innerHTML;
    for (const key of this.styleKeys) {
      this.originalParentStyles[key] = this.element.style[key];
    }
    this.buildQuill();
    this.dispatchChangeEvent({ reason: "init" });
  }

  reset() {
    this.debug("reset");
    this.element.innerHTML = this.originalInnerHtml;
    for (const key of this.styleKeys) {
      this.element.style[key] = this.originalParentStyles[key];
    }

    this.buildQuill();

    // Trigger the selection-change event to initialize variables and emit format-change event so that the controls update
    this.quill.setSelection(0, 0);

    this.dispatchChangeEvent({ reason: "reset" });
  }

  buildQuill() {
    this.quill = new Quill(this.element, {
      // debug: "info",
      readOnly: this.options.readOnly,
    });

    this.quill.on("selection-change", (range, oldRange, source) => {
      this.debug("selection-change event", range, oldRange, source);
      // Don't use the selection change while making a change, since that would give us outdated values
      if (this.isWithinChange) return;

      if (range) {
        this.debug("lastValidSelection =", JSON.stringify(range));
        this.lastValidSelection = range;
      }

      // When the editor is unfocused, the range is null (calling getFormat would force focus on back on the editor, making it impossible for the user to focus another element),
      // therefore we won't dispatch our format-change event in this case.
      if (range) {
        const format = this.quill.getFormat();
        // this.debug("format", JSON.stringify(format));
        const featureValueDict = parseFontFeaturesCssValue(
          format["font-feature-settings"]
        );

        // Dispatch custom event 'format-change'
        this.dispatchEvent("format-change", {
          features: featureValueDict,
          format,
        });
      }

      if (!oldRange !== !range) {
        this.dispatchEvent("focus-change", {
          hasFocus: (this.hasFocus = !!range),
        });
      }
    });

    this.quill.on("text-change", (delta, oldDelta, source) => {
      this.debug("text-change event", delta, oldDelta, source);
      this.dispatchChangeEvent({
        reason: "text-change",
        quill: { delta, oldDelta, source },
      });
    });
  }

  dispatchEvent(name, detail) {
    this.debug("text-editor event:", name, detail);
    this.element.dispatchEvent(new CustomEvent(name, { detail }));
  }

  get mainFormat() {
    const r = {};
    for (const key of this.styleKeys) {
      if (key === "fontFamily") {
        r[key] = this.fontCode;
        r["fontFamilyArray"] = this.getFontCodes();
        continue;
      }
      r[key] = this.element.style[key];
    }
    return r;
  }

  dispatchChangeEvent(detail = {}) {
    this.dispatchEvent("change", {
      html: this.element.querySelector(".ql-editor").innerHTML,
      format: this.mainFormat,
      ...detail,
    });
  }

  get fontCode() {
    return removeQuotes(this.element.style["fontFamily"]);
  }

  getFontCodes() {
    const contents = this.quill.getContents();

    // Example contents:
    // {
    //   "ops": [
    //     { "insert": "Baton", "attributes": { "letter-spacing": "-0.12em", "font-family": "f114" } },
    //     { "insert": "\n" },
    //     { "insert": "Nouveau", "attributes": { "letter-spacing": "-0.12em", "font-family": "f114" } },
    //     { "insert": "\n" }
    //   ]
    // }

    const charCountByFontCode = {};
    charCountByFontCode[this.fontCode] = 0; // to count as a default in case there are no non-whitespace characters
    for (const op of contents.ops) {
      const visibleCharCount = op.insert.replaceAll(/\s+/g, "").length;
      if (visibleCharCount === 0) continue;

      const fontCode =
        (op.attributes && op.attributes["font-family"]) ?? this.fontCode;
      if (!fontCode) continue;

      charCountByFontCode[fontCode] ??= 0;
      charCountByFontCode[fontCode] += visibleCharCount;
    }

    return Object.entries(charCountByFontCode)
      .sort((a, b) => b[1] - a[1])
      .map(([fontCode, count]) => fontCode);
  }

  addEventListener(event, callback) {
    return this.element.addEventListener(event, callback);
  }

  removeEventListener(event, callback) {
    return this.element.removeEventListener(event, callback);
  }

  get currentFormat() {
    const format = this.quill.getFormat();
    return format;
  }

  setFont(fontName) {
    if (this.willFormattingApplyToTheWholeText) {
      this.element.style.fontFamily = fontName;
    }
    this.format("font-family", fontName);
    // this.dispatchChangeEvent is triggered by format()
  }

  setFeatures(featuresDict) {
    this.format(
      "font-feature-settings",
      generateFontFeaturesCssValue(featuresDict)
    );
  }

  setFontSize(size) {
    this.element.style.fontSize = size + "px";
    // set data-font-size; this is used when scaling for narrow screens
    this.element.dataset.fontSize = size;
    this.dispatchChangeEvent({ reason: "font-size-change" });
  }

  setLineHeight(lineHeight) {
    this.element.style.lineHeight = lineHeight;
    this.format("line-height", lineHeight, { applyToAll: true });
    // this.dispatchChangeEvent is triggered by format()
  }

  setLetterSpacing(letterSpacing) {
    this.format("letter-spacing", letterSpacing + "em", { applyToAll: true });
    // this.dispatchChangeEvent is triggered by format()
  }

  get fullRange() {
    return { index: 0, length: this.quill.getLength() };
  }

  format(property, value, { applyToAll = false } = {}) {
    window.quill = this.quill;
    this.change(() => {
      // Focus so that the text appears selected
      this.quill.focus({ preventScroll: true });

      let rangeToFormat = applyToAll ? this.fullRange : this.lastValidSelection;
      this.debug("rangeToFormat", rangeToFormat, this.quill.getLength());

      const isNone = !rangeToFormat || rangeToFormat.length === 0;
      let isAll =
        rangeToFormat && rangeToFormat.length === this.quill.getLength() - 1; // -1 because the editor always has at least one newline character, which is not reflected in the selection

      if (isNone) {
        rangeToFormat = this.fullRange;
        isAll = true;
      }

      const { index, length } = rangeToFormat;
      this.debug("formatText", index, length, property, value);

      // https://quilljs.com/docs/api#formattext
      this.quill.formatText(index, length, property, value, "silent");

      // Fix problem with column gap:
      // The column gap is relative to the current font size and is set on the editor element.
      if (isAll && property === "font-size") {
        this.element.style.fontSize = value;
      }

      // --- This is only relevant if we want to apply font-size to selection
      // Fix problem with line-height:
      // The line-height is applied to paragraphs and font-size is applied to the span within the paragraph,
      // so the font-size is not taken into account when calculating the line-height.
      // Here we set the font-size on the paragraphs that contain a single span.
      // BUG: set font size very high, select text, apply a feature, lower font size => line-height stays very large because a large font-size is applied to part of the text
      // this.element.querySelectorAll(".ql-editor p > span").forEach((span) => {
      //   const p = span.parentElement;
      //   if (p && p.childNodes.length === 1) {
      //     p.style.fontSize = span.style.fontSize;
      //   }
      // });
      // ---

      this.dispatchChangeEvent({
        reason: "format-change",
      });
    });
  }

  get isAllSelected() {
    return (
      this.lastValidSelection &&
      this.lastValidSelection.index === 0 &&
      this.lastValidSelection.length === this.quill.getLength() - 1 // -1 because the editor always has at least one newline character, which is not reflected in the selection
    );
  }

  get isNoneSelected() {
    return !this.lastValidSelection || this.lastValidSelection.length === 0;
  }

  get willFormattingApplyToTheWholeText() {
    return this.isAllSelected || this.isNoneSelected;
  }

  change(fn) {
    try {
      this.isWithinChange = true;
      fn();
    } finally {
      this.isWithinChange = false;
    }
  }

  setAlignment(alignment) {
    this.element.style.textAlign = alignment;
    this.dispatchChangeEvent({ reason: "alignment-change" });
  }

  setColumns(columns) {
    this.element.style.columns = columns;
    this.dispatchChangeEvent({ reason: "columns-change" });
  }
}

// { ss01: true, ss02: true } => "ss01" on, "ss02" on
function generateFontFeaturesCssValue(features) {
  const result = [];
  for (const [key, value] of Object.entries(features)) {
    if (value) {
      result.push(`"${key}" ${value ? "on" : "off"}`);
    }
  }
  return result.join(", ");
}

// "ss01" on, "ss02" 1, "ss03" 0 => { ss01: true, ss02: true, ss03: false }
function parseFontFeaturesCssValue(value) {
  if (!value) return {};

  if (Array.isArray(value)) {
    // There are multiple formats used in the selection, return nothing
    return {};
  }

  if (typeof value !== "string") {
    console.warn("Unexpected format for font-feature-settings", value);
    return {};
  }

  const features = value.split(",");
  const result = {};
  for (const feature of features) {
    const [key, value] = feature.trim().split(" ");
    result[key.slice(1, -1)] = !(value === "off" || value === "0");
  }
  return result;
}

function removeQuotes(str) {
  return str.replace(/^["'](.*)["']$/, "$1");
}
