import { Font } from "./lib-font-ext/lib-font-ext";

function loadFont(fontInfo) {
  if (!fontInfo.src) {
    return Promise.reject(`No font src in ${JSON.stringify(fontInfo)}`);
  }
  return new Promise((resolve, reject) => {
    const myFont = new Font(fontInfo.name, { ext: fontInfo.ext });
    myFont.onerror = (evt) => reject(evt);
    myFont.onload = (evt) => resolve(evt.detail.font);
    myFont.src = fontInfo.src;
  });
}

export async function getFontFeatures(fontInfo) {
  const font = await loadFont(fontInfo);

  const GSUB = font.opentype.tables.GSUB;

  const features = new Set();
  const names = {};

  GSUB.getSupportedScripts().forEach((script) => {
    const scriptTable = GSUB.getScriptTable(script);
    GSUB.getSupportedLangSys(scriptTable).forEach((langSys) => {
      const langSysTable = GSUB.getLangSysTable(script, langSys);
      GSUB.getFeatures(langSysTable)?.forEach((feature) => {
        features.add(feature.featureTag);
        const uiNameId = feature.getFeatureParams()?.UINameID;
        const name = uiNameId
          ? font.opentype.tables.name.get(uiNameId)
          : getFeatureStandardName(feature.featureTag);

        if (
          name &&
          names[feature.featureTag] &&
          names[feature.featureTag] !== name
        ) {
          console.warn(
            `Feature ${feature.featureTag} has multiple names: ${
              names[feature.featureTag]
            } and ${name}`
          );
        }

        names[feature.featureTag] = name;
      });
    });
    console.groupEnd();
  });

  return Array.from(features).map((feature) => ({
    tag: feature,
    name: names[feature],
  }));
}

const standardNames = {
  // https://learn.microsoft.com/en-us/typography/opentype/spec/features_ae
  aalt: "Access All Alternates",
  abvf: "Above-base Forms",
  abvm: "Above-base Mark Positioning",
  abvs: "Above-base Substitutions",
  afrc: "Alternative Fractions",
  akhn: "Akhand",
  blwf: "Below-base Forms",
  blwm: "Below-base Mark Positioning",
  blws: "Below-base Substitutions",
  calt: "Contextual Alternates",
  case: "Case-Sensitive Forms",
  ccmp: "Glyph Composition/Decomposition",
  cfar: "Conjunct Form After Ro",
  chws: "Contextual Half-width Spacing",
  cjct: "Conjunct Forms",
  clig: "Contextual Ligatures",
  cpct: "Centered CJK Punctuation",
  cpsp: "Capital Spacing",
  cswh: "Contextual Swash",
  curs: "Cursive Positioning",
  c2pc: "Petite Capitals From Capitals",
  c2sc: "Small Capitals From Capitals",
  dist: "Distances",
  dlig: "Discretionary Ligatures",
  dnom: "Denominators",
  dtls: "Dotless Forms",
  expt: "Expert Forms",

  // https://learn.microsoft.com/en-us/typography/opentype/spec/features_fj
  falt: "Final Glyph on Line Alternates",
  fin2: "Terminal Form #2",
  fin3: "Terminal Form #3",
  fina: "Terminal Forms",
  flac: "Flattened ascent forms",
  frac: "Fractions",
  fwid: "Full Widths",
  half: "Half Forms",
  haln: "Halant Forms",
  halt: "Alternate Half Widths",
  hist: "Historical Forms",
  hkna: "Horizontal Kana Alternates",
  hlig: "Historical Ligatures",
  hngl: "Hangul (DEPRECATED)",
  hojo: "Hojo Kanji Forms (JIS X 0212-1990 Kanji Forms)",
  hwid: "Half Widths",
  init: "Initial Forms",
  isol: "Isolated Forms",
  ital: "Italics",
  jalt: "Justification Alternates",
  jp78: "JIS78 Forms",
  jp83: "JIS83 Forms",
  jp90: "JIS90 Forms",
  jp04: "JIS2004 Forms",

  // https://learn.microsoft.com/en-us/typography/opentype/spec/features_ko
  kern: "Kerning",
  lfbd: "Left Bounds",
  liga: "Standard Ligatures",
  ljmo: "Leading Jamo Forms",
  lnum: "Lining Figures",
  locl: "Localized Forms",
  ltra: "Left-to-right glyph alternates",
  ltrm: "Left-to-right mirrored forms",
  mark: "Mark Positioning",
  med2: "Medial Forms #2",
  medi: "Medial Forms",
  mgrk: "Mathematical Greek",
  mkmk: "Mark to Mark Positioning",
  mset: "Microsoft Set",
  nalt: "Alternate Annotation Forms",
  nlck: "NLC Kanji Forms",
  nukt: "Nukta Forms",
  numr: "Numerators",
  onum: "Oldstyle Figures",
  opbd: "Optical Bounds",
  ordn: "Ordinals",
  ornm: "Ornaments",

  // https://learn.microsoft.com/en-us/typography/opentype/spec/features_pt
  palt: "Proportional Alternate Widths",
  pcap: "Petite Capitals",
  pkna: "Proportional Kana",
  pnum: "Proportional Figures",
  pref: "Pre-base Forms",
  pres: "Pre-base Substitutions",
  pstf: "Post-base Forms",
  psts: "Post-base Substitutions",
  pwid: "Proportional Widths",
  qwid: "Quarter Widths",
  rand: "Randomize",
  rclt: "Required Contextual Alternates",
  rkrf: "Rakar Forms",
  rlig: "Required Ligatures",
  rphf: "Reph Form",
  rtbd: "Right Bounds",
  rtla: "Right-to-left alternates",
  rtlm: "Right-to-left mirrored forms",
  ruby: "Ruby Notation Forms",
  rvrn: "Required Variation Alternates",
  salt: "Stylistic Alternates",
  sinf: "Scientific Inferiors",
  size: "Optical size",
  smcp: "Small Capitals",
  smpl: "Simplified Forms",
  ssty: "Math script style alternates",
  stch: "Stretching Glyph Decomposition",
  subs: "Subscript",
  sups: "Superscript",
  swsh: "Swash",
  titl: "Titling",
  tjmo: "Trailing Jamo Forms",
  tnam: "Traditional Name Forms",
  tnum: "Tabular Figures",
  trad: "Traditional Forms",
  twid: "Third Widths",

  // https://learn.microsoft.com/en-us/typography/opentype/spec/features_uz
  unic: "Unicase",
  valt: "Alternate Vertical Metrics",
  vatu: "Vattu Variants",
  vchw: "Vertical Contextual Half-width Spacing",
  vert: "Vertical Alternates",
  vhal: "Alternate Vertical Half Metrics",
  vjmo: "Vowel Jamo Forms",
  vkna: "Vertical Kana Alternates",
  vkrn: "Vertical Kerning",
  vpal: "Proportional Alternate Vertical Metrics",
  vrt2: "Vertical Alternates and Rotation",
  vrtr: "Vertical Alternates for Rotation",
  zero: "Slashed Zero",
};

function getFeatureStandardName(tag) {
  if (tag.match(/cv\d{2}/)) {
    return `Character Variant ${parseInt(tag.slice(2))}`;
  }
  if (tag.match(/ss\d{2}/)) {
    return `Stylistic Set ${parseInt(tag.slice(2))}`;
  }
  return standardNames[tag] ?? tag;
}
