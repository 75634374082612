import { FontFeatureSelector } from "./formatting-tools/FontFeatureSelector";
import { FontSizeSelector } from "./formatting-tools/FontSizeSelector";
import { FontSpacingSelector } from "./formatting-tools/FontSpacingSelector";
import { FontStyleSelector } from "./formatting-tools/FontStyleSelector";
import { AlignmentSelector } from "./formatting-tools/AlignmentSelector";
import { ColumnsSelector } from "./formatting-tools/ColumnsSelector";
import { ResetSelector } from "./formatting-tools/ResetSelector";
import { Panel } from "./formatting-tools/Panel";
import { preferences } from "./preferences";
import { TextEditor } from "./text-editor/TextEditor";
import { onHoverStateChange, addRemoveClass } from "common/dom-utils";
import { h } from "common/dom-builder";
import { debug } from "debug";
import { debounce } from "lodash";

const panelContentClasses = {
  style: FontStyleSelector,
  feature: FontFeatureSelector,
  size: FontSizeSelector,
  spacing: FontSpacingSelector,
  alignment: AlignmentSelector,
  columns: ColumnsSelector,
  reset: ResetSelector,
};

// CSS transition duration
export const transitionTimeout = 200; // milliseconds

// Can be used for scheduling timers to wait for the transition to complete.
export const transitionTimeoutWithExtra = transitionTimeout + 50;

const instances = new Map();

/**
 * A representation of the whole font sample component, including the editor and the controls.
 */
export class TypeTester {
  id;
  textEditor;
  props;
  element;
  panels = {};
  isHovered = false;
  settingsVisibilityGroup = {}; // used by Panel & PopupVisibilityState to make sure only one of the panels that push down the editor (ie. not overlap) is open at a time
  isEditable;
  isFormattable;

  constructor(element) {
    instances.set(element, this);
    this.element = element;
    this.renderedTextElement = element.querySelector(".rendered_text");
    this.id = `sample-${uniqueId()}`;
    this.debug = debug(`type-tester:TypeTester:${this.id}`);
    this.originalHtml = this.element.innerHTML;
    this.init();
  }

  static currentlyActiveTypeTester = null;

  static getInstanceByElement(element) {
    return instances.get(element);
  }

  async init() {
    if (!window.typeTesterOptions) {
      console.error("window.typeTesterOptions not defined");
    }
    this.props = deepCamelizeKeys({
      ...window.typeTesterOptions,
      ...JSON.parse(this.element.dataset.props),
    });
    this.debug("props", this.props);
    this.isEditable = this.props.options.isEditable;
    this.isFormattable = this.props.options.isResizable;

    // Attach event listeners before instantiating TextEditor, so that we get the events triggered during TextEditor initialization
    this.initEventListeners();

    this.textEditor = new TextEditor(this.renderedTextElement, {
      readOnly: !this.isEditable,
    });

    this.render();
    this.initInputGroupPanels();
    this.element.style.setProperty(
      "--transition-params",
      `${transitionTimeout}ms ease`
    );
  }

  render() {
    const headerElement = this.element.querySelector(".header");
    headerElement.querySelector(".style-input-group").insertAdjacentElement(
      "afterend",
      h(
        "div",
        { class: ["font-settings", "visible-only-when-active"] },
        preferences.toolOrder
          .split(",")
          .map((groupName) => groupName.trim())
          .map((groupName) => {
            if (!groupName) return null;
            const klass = panelContentClasses[groupName];
            if (!klass) {
              console.error(
                "Unknown tool name in the configuration:",
                groupName
              );
              return null;
            }
            return klass.buildElementForHeader(this);
          })
          .filter((el) => el)
      )
    );
  }

  getInputGroupPanelElement(groupName) {
    return this.element.querySelector(`.${groupName}-panel`);
  }

  initInputGroupPanels() {
    if (preferences.alwaysShowFontStyle) {
      this.element
        .querySelector(".style-input-group")
        .classList.remove("visible-only-when-active");
    }
    this.element
      .querySelectorAll(".input-group.expandable")
      .forEach((inputGroup) => {
        const groupName = inputGroup.dataset.inputGroup;
        this.debug("groupName", groupName);

        // Create panel if it doesn't exist
        this.initPanel(inputGroup, groupName);
      });

    // Init the opentype panel separately since it does not have a corresponding input group
    this.initPanel(null, "feature");
  }

  initPanel(inputGroup, groupName) {
    this.debug("initPanel", groupName);

    if (this.panels[groupName]) {
      this.debug("Panel already exists:", groupName);
      return;
    }

    const usePushdown =
      !inputGroup || inputGroup.classList.contains("expand-with-pushdown");

    const panel = new Panel(
      inputGroup,
      groupName,
      usePushdown,
      this,
      panelContentClasses[groupName]
    );
    this.panels[groupName] = panel;
    return panel;
  }

  initEventListeners() {
    // Active State - show tools when hovered or panel is open
    onHoverStateChange(this.element, (isHovered) => {
      this.isHovered = isHovered;
      this.debug(this.isHovered ? "hovered" : "not hovered");
      addRemoveClass(this.element, "hovered", isHovered);
      if (preferences.expandSampleOnHover) {
        if (isHovered) {
          this.activate();
        } else {
          // When deactivateOnMouseLeave enabled:
          // - If activated via hover, deactivate when the mouse leaves
          // - If activated via click, don't deactivate when the mouse leaves
          if (!this.wasFocussed || preferences.deactivateOnMouseLeave) {
            this.deactivate();
          }
        }
      }
    });
    this.renderedTextElement.addEventListener("click", () => {
      // In case it's readonly and there is no focus event from the editor
      this.activate();
    });
    this.renderedTextElement.addEventListener("focus-change", (event) => {
      this.debug("focus-change", event.detail);
      if (event.detail.hasFocus) {
        this.debug("got focus");
        this.wasFocussed = true;
        this.activate();
      }
    });
    this.element.addEventListener(
      "interaction",
      debounce(() => {
        this.wasFocussed = true;
        this.activate();
      }, 10)
    );
    this.renderedTextElement.addEventListener("change", (event) => {
      this.dispatchEvent("change", event.detail);
    });
  }

  get isActive() {
    return TypeTester.currentlyActiveInstance === this;
  }

  activate() {
    this.debug("activate");
    if (this.isActive) {
      this.debug("already active");
      return;
    }

    TypeTester.currentlyActiveInstance?.deactivate();
    TypeTester.currentlyActiveInstance = this;
    this.element.classList.add("active");

    if (this.isFormattable) {
      nowAndOnResize(() => {
        this.panels["feature"].visibility.showHide(
          this.isActive && !this.isScreenNarrow
        );
      });
    }
  }

  get headerHeights() {
    return (
      (document.querySelector("body > header")?.offsetHeight ?? 0) +
      (document.querySelector("body > .header-bottom")?.offsetHeight ?? 0)
    );
  }

  deactivate() {
    this.debug("deactivate");
    TypeTester.currentlyActiveInstance = null;
    this.element.classList.remove("active");
    this.panels["feature"].visibility.showHide(false);
    this.wasFocussed = false;
  }

  get isAnyPanelVisible() {
    return Object.values(this.panels).some(
      (panel) => panel.visibility.isVisible
    );
  }

  pushdownHeight = 0;
  pushdownTimeout = null;

  // The "pushdown" is the amount by which we move the editor down when a panel is open, so that the panel does not overlap the editor.
  // It was done this way so that when the user expands a different panel while one is already open, the pushdown is smoothly transitioned to the
  // height of the new panel directly, without first going back to 0 and then to the new height.
  adjustPushdownHeight(isVisible, panel) {
    if (this.pushdownTimeout) {
      clearTimeout(this.pushdownTimeout);
      this.pushdownTimeout = null;
    }

    const isNonZero = isVisible && panel.usePushdown;

    let totalDelay = 0;

    const attempt = () => {
      if (isNonZero && panel.contentHeight == 0) {
        if (totalDelay > 3000) {
          console.error("Too many attempts to adjust pushdown height");
          return;
        }

        const delay = totalDelay * 1.5;
        totalDelay += delay;

        this.pushdownTimeout = setTimeout(attempt, delay);
        return;
      }

      // Calculate what the element height should be without pushdown (note, that the height can change due to font-size/spacing settings, so we cannot just use the value before the pushdown was applied)
      const netHeight = this.element.clientHeight - this.pushdownHeight;

      const pushdownHeight = isNonZero ? panel.contentHeight + 32 : 0;
      this.pushdownHeight = pushdownHeight;

      // Set the height of the element to make sure the sample text is always pushed down, instead of the header being pushed up
      this.element.style.height = `${this.element.clientHeight}px`; // transition starting point
      this.renderedTextElement.style.marginTop = `${pushdownHeight}px`;

      this.pushdownTimeout = setTimeout(() => {
        this.element.style.height = `${netHeight + pushdownHeight}px`;

        // After the transition, unset the height so that it can automatically react to changes in the font size/spacing
        this.pushdownTimeout = setTimeout(() => {
          this.element.style.height = "";
        }, transitionTimeoutWithExtra);
      }, 20);
    };

    attempt();
  }

  reset() {
    this.textEditor.reset();
    this.dispatchEvent("reset");
  }

  addEventListener(event, callback) {
    return this.element.addEventListener(event, callback);
  }

  removeEventListener(event, callback) {
    return this.element.removeEventListener(event, callback);
  }

  dispatchEvent(name, detail = {}) {
    this.debug("event:", name, detail);
    this.element.dispatchEvent(new CustomEvent(name, { detail }));
  }

  get isScreenNarrow() {
    return window.innerWidth < 1000;
  }

  static handleDocumentClick(event) {
    if (
      TypeTester.currentlyActiveInstance &&
      !TypeTester.currentlyActiveInstance.element.contains(event.target)
    ) {
      TypeTester.currentlyActiveInstance.deactivate();
    }
  }
}

let lastId = 0;
function uniqueId() {
  return ++lastId;
}

function deepCamelizeKeys(obj) {
  if (obj == null || typeof obj === "undefined") return obj;

  if (Array.isArray(obj)) {
    return obj.map((item) => deepCamelizeKeys(item));
  } else if (typeof obj === "object") {
    const newObj = {};
    for (const key in obj) {
      newObj[camelize(key)] = deepCamelizeKeys(obj[key]);
    }
    return newObj;
  } else {
    return obj;
  }
}

function camelize(str) {
  return str.replace(/[-_]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));
}

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function nowAndOnResize(callback) {
  callback();
  window.addEventListener("resize", callback);
}
