import { TypeTester } from "./TypeTester";

// Entry point for the type tester

export async function initEditor(editorContainer) {
  new TypeTester(editorContainer);
}

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".type-tester").forEach(initEditor);
});

document.addEventListener("click", (event) => {
  TypeTester.handleDocumentClick(event);
});
