import { h } from "common/dom-builder";
import { preferences } from "../preferences";

export class FontStyleSelector {
  parent;
  element;

  constructor(props) {
    this.element = props.panel.panelElement;
    this.props = props;
    this.init();
  }

  init() {
    this.props.typeTester.textEditor.addEventListener(
      "format-change",
      ({ detail }) => {
        // console.log("format-change", JSON.stringify(detail));
        this.updateCurrentFontByCode(detail.format["font-family"]);
      }
    );
    this.props.panel.containerElement.classList.add("covers-label");
    this.headerItemElement =
      this.props.typeTester.element.querySelector(".style-input-group");
    this.update();
  }

  update() {
    const { fontStyle } = this.props.typeTester.props;
    this.updateCurrentFontStyle(fontStyle);

    this.render();
  }

  get fontFamily() {
    return this.props.typeTester.props.fontFamily;
  }

  updateCurrentFontByCode(fontFamilyCode) {
    const { fontFamily } = this;
    const { fontStyles } = fontFamily;

    // Find the font style by code
    let fontStyle = fontStyles.find(
      (fontStyle) => fontStyle.code === fontFamilyCode
    );

    fontStyle ??= this.props.typeTester.props.fontStyle;
    this.updateCurrentFontStyle(fontStyle);
  }

  updateCurrentFontStyle(fontStyle) {
    // Update the list of OpenType features
    this.props.typeTester.panels["feature"]?.panelContents?.setFont(fontStyle);

    this.currentFontStyle = fontStyle;

    this.updateCurrentLabel();
  }

  updateCurrentLabel() {
    this.headerItemElement
      .querySelector(".tyte-label")
      .replaceChildren(this.fontStyleEntryName(this.currentFontStyle));

    this.element.querySelector(".selected")?.classList?.remove("selected");
    this.element
      .querySelector(`[data-code="${this.currentFontStyle.code}"]`)
      ?.classList?.add("selected");
  }

  render() {
    if (!this.props.typeTester.isFormattable) return;

    const { fontFamily } = this.props.typeTester.props;

    if (preferences.showFontFamilyName) {
      this.props.typeTester.element
        .querySelector(".header")
        .classList.add("with-font-family-name");
    }

    // Replace the label with the font style name according to preferences (including font family name or not)
    this.updateCurrentLabel();

    // Insert options for the dropdown
    fontFamily.fontStyles.forEach((fontStyle) => {
      const optionElement = h("div", { class: "option" }, [
        this.fontStyleEntryName(fontStyle),
      ]);

      optionElement.dataset.code = fontStyle.code;

      if (fontStyle.code === this.currentFontStyle.code) {
        optionElement.classList.add("selected");
      }

      optionElement.addEventListener("click", () => {
        // Modify text in the editor
        this.props.typeTester.textEditor.setFont(fontStyle.code);

        this.updateCurrentFontStyle(fontStyle);

        this.props.closePanel();

        this.props.typeTester.element.dispatchEvent(
          new CustomEvent("interaction")
        );
      });

      this.element.appendChild(optionElement);
    });
  }

  fontStyleEntryName(fontStyle) {
    if (preferences.showFontFamilyName) {
      return h("span", {}, [
        h("span", { class: "font-family-name" }, this.fontFamily.name + " "),
        h("span", {}, fontStyle.name),
      ]);
    } else {
      return fontStyle.name;
    }
  }
}
