export function h(type, attrs = {}, children = null) {
  const element = document.createElement(type);

  // Apply attributes
  Object.keys(attrs).forEach((key) => {
    if (key === "class") {
      const classes = Array.isArray(attrs[key])
        ? attrs[key]
        : attrs[key].split(/\s+/);
      classes.forEach((className) => {
        if (className) element.classList.add(className);
      });
    } else {
      element.setAttribute(key, attrs[key]);
    }
  });

  const addChild = (child) => {
    if (child instanceof HTMLElement) {
      element.appendChild(child);
    } else {
      element.appendChild(document.createTextNode(child));
    }
  };

  // Append children
  if (children && typeof children === "function") {
    children(element);
  } else if (children) {
    Array.isArray(children)
      ? children.forEach((child) => addChild(child))
      : addChild(children);
  }

  return element;
}
