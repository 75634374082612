import { getFontFeatures } from "../font-parsing/font-parsing";
import { h } from "common/dom-builder";

export class FontFeatureSelector {
  id;
  element;
  font;
  props;
  currentFeaturesDict = {};
  showPanelWhileSampleActive = true;

  constructor(props) {
    this.element = props.panel.panelElement;
    this.props = props;
    this.font = props.font;
    this.id = `${this.props.typeTester.id}-feature`; // E.g. 'sample-1-feature'
    this.offsetLeft = this.props.typeTester.element.querySelector(
      ".feature-input-group"
    );
    this.init();
  }

  static buildElementForHeader(typeTester) {
    // Not rendering the label, will be opened automatically, when the sample is focussed
    return null;

    return h(
      "div",
      {
        class: [
          "input-group",
          "feature-input-group",
          "expandable",
          "expand-with-pushdown",
        ],
        "data-input-group": "feature",
      },
      [
        h("span", { class: "label" }, "OpenType"),
        h("span", { class: "icon-button expand-button" }),
      ]
    );
  }

  init() {
    this.props.panel.containerElement.classList.add("distant", "center");

    this.props.typeTester.textEditor.addEventListener(
      "format-change",
      ({ detail }) => {
        // console.log("format-change", detail);
        this.updateCurrentFeatures(detail.features);
      }
    );

    this.update();
  }

  setFont(font) {
    this.font = font;
    this.update();
  }

  async update() {
    try {
      this.features = filterFeatures(
        await getFontFeatures({
          name: this.font.name,
          src: this.font.otfDesktopFileUrl,
          ext: "otf",
        })
      );
    } catch {
      this.features = [];
    }
    this.render();
  }

  async render() {
    const formElement = h("form", { id: `${this.id}-form` });

    let columnElement;

    const featureCodesAdded = new Set();

    // Find exclusive features
    const exclusiveFeatures = exclusiveFeatureCodes
      .map((featureCode) =>
        this.features.find((feature) => feature.tag === featureCode)
      )
      .filter((feature) => feature);

    const useExclusiveFeatures = exclusiveFeatures.length >= 2;

    for (const feature of this.features) {
      if (featureCodesAdded.size % 5 === 0) {
        columnElement = h("div");
        columnElement.classList.add("column");
        formElement.appendChild(columnElement);
      }

      const type =
        useExclusiveFeatures && exclusiveFeatureCodes.includes(feature.tag)
          ? "radio"
          : "checkbox";
      this.renderItem(columnElement, feature, type);
      featureCodesAdded.add(feature.tag);
    }

    this.element.replaceChildren(formElement);

    this.updateCheckboxStates();
  }

  renderItem(parentElement, feature, type) {
    const itemElement = h("div");
    itemElement.classList.add("item");

    if (type === "radio") {
      addRadioWithLabel(itemElement, {
        id: `${this.id}-${feature.tag}`,
        name: "feature",
        value: feature.tag,
        label: feature.name ?? `(${feature.tag})`,
        onChange: (ev) => {
          ev.stopPropagation();
          const newValue = ev.target.checked;

          this.setFeatureValue(feature.tag, newValue, { exclusive: true });
        },
      });
    } else {
      addCheckboxWithLabel(itemElement, {
        id: `${this.id}-${feature.tag}`,
        name: "feature",
        value: feature.tag,
        label: feature.name ?? `(${feature.tag})`,
        onChange: (ev) => {
          ev.stopPropagation();
          const newValue = ev.target.checked;
          this.setFeatureValue(feature.tag, newValue, { exclusive: false });
        },
      });
    }

    parentElement.appendChild(itemElement);
  }

  setFeatureValue(featureCode, newValue, { exclusive }) {
    if (exclusive) {
      // Turn off all other exclusive features
      exclusiveFeatureCodes.forEach((code) => {
        if (code !== featureCode) {
          delete this.currentFeaturesDict[code];
        }
      });
    }

    this.currentFeaturesDict[featureCode] = newValue;

    this.props.typeTester.textEditor.setFeatures({
      ...defaultFeaturesDict,
      ...this.currentFeaturesDict,
    });

    this.props.typeTester.element.dispatchEvent(new CustomEvent("interaction"));
  }

  // When the user changes the selection, reflect the currently applied features in the UI
  updateCurrentFeatures(featuresDict) {
    featuresDict = withoutKeys(featuresDict, defaultFeatureCodes);
    this.currentFeaturesDict = featuresDict;

    this.update();
  }

  updateCheckboxStates() {
    const featuresDict = this.currentFeaturesDict;

    // If empty
    if (Object.keys(featuresDict).length === 0) {
      // Clear radio button selection
      const formElement = document.getElementById(`${this.id}-form`);
      if (!formElement) {
        console.warn(`Could not find form element ${this.id}-form`);
        return;
      }
      formElement.querySelectorAll("input[type=radio]").forEach((radio) => {
        radio.checked = false;
      });
      return;
    }

    for (const featureCode in featuresDict) {
      if (defaultFeaturesDict[featureCode]) continue;
      const radioId = `${this.id}-${featureCode}`;
      const radio = document.getElementById(radioId);
      if (!radio) {
        console.warn(`Could not find radio element ${radioId}`);
        continue;
      }

      radio.checked = featuresDict[featureCode];
    }
  }
}

const defaultFeaturesDict = {
  kern: true,
  liga: true,
  salt: true,
  calt: true,
};

const defaultFeatureCodes = Object.keys(defaultFeaturesDict);

const ignoredFeatureCodes = [
  "aalt",
  "dnom",
  "numr",
  "ordn",
  "sinf",
  "subs",
  "sups",
  "locl",
  "pnum",
  "ccmp",

  // Defaults
  "kern",
  "liga",
  "salt",
  "calt",
];

const exclusiveFeatureCodes = ["pnum", "lnum", "onum", "tnum"];

const multiFeatureCodes = [
  "case",
  "frac",
  "dlig",
  "ss01",
  "ss02",
  "ss03",
  "ss04",
  "ss05",
  "ss06",
  "ss07",
  "ss08",
  "ss09",
  "ss10",
  "ss11",
  "ss12",
  "ss13",
  "ss14",
  "ss15",
  "ss16",
  "ss17",
  "ss18",
  "ss19",
  "ss20",
];

const allFeatureCodes = [...exclusiveFeatureCodes, ...multiFeatureCodes];

function filterFeatures(features) {
  const r = [];
  const unprocessedFeatures = [...features];

  allFeatureCodes.forEach((feature) => {
    const index = unprocessedFeatures.findIndex((f) => f.tag === feature);
    if (index !== -1) {
      if (!ignoredFeatureCodes.includes(feature)) {
        r.push(unprocessedFeatures[index]);
      }
      unprocessedFeatures.splice(index, 1);
    }
  });

  unprocessedFeatures.forEach((feature) => {
    if (!ignoredFeatureCodes.includes(feature.tag)) {
      console.warn(`Unexpected feature:`, feature.tag, feature.name);
      r.push(feature);
    }
  });
  return r;
}

function addCheckboxWithLabel(parent, { id, name, value, label, onChange }) {
  const input = addCheckbox(parent, { id, name, value });
  input.addEventListener("change", onChange);

  addLabel(parent, { label, inputId: id });
}

function addRadioWithLabel(parent, { id, name, value, label, onChange }) {
  const input = addRadio(parent, { id, name, value });
  input.addEventListener("change", onChange);

  addLabel(parent, { label, inputId: id });
}

function addCheckbox(parent, { id, name, value }) {
  const input = h("input", { id, type: "checkbox", name, value });
  parent.appendChild(input);
  return input;
}

function addRadio(parent, { id, name, value }) {
  const input = h("input", { id, type: "radio", name, value });
  parent.appendChild(input);
  return input;
}

function addLabel(parent, { label, inputId }) {
  const labelElement = h("label");
  labelElement.htmlFor = inputId;
  labelElement.textContent = label;
  parent.appendChild(labelElement);
  return label;
}

function withoutKeys(obj, keys) {
  const r = { ...obj };
  keys.forEach((key) => delete r[key]);
  return r;
}
