import { Font as LibFont } from "lib-font";
import { setupFontFace } from "./fontface";

// These need to be imported in order to handle compressed font files
// import "./inflate";
// import "./unbrotli";

export class Font extends LibFont {
  // Need to override `src=` becuase we customize setupFontFace
  set src(src) {
    this.__src = src;
    (async () => {
      if (globalThis.document && !this.options.skipStyleSheet) {
        await setupFontFace(this.name, src, this.options);
      }
      this.loadFont(src);
    })();
  }
}
