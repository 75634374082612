import { h } from "common/dom-builder";

export class AlignmentSelector {
  static buildElementForHeader(typeTester) {
    if (!typeTester.isFormattable) return;

    const element = h(
      "div",
      {
        class: ["input-group", "alignment-input-group", "button-group"],
        "data-input-group": "alignment",
      },
      [
        h("div", { class: "icon-button align-left-button" }),
        h("div", { class: "icon-button align-center-button" }),
      ]
    );
    new AlignmentSelector({ element, typeTester });
    return element;
  }

  constructor(props) {
    this.element = props.element;
    this.props = props;
    this.init();
  }

  init() {
    ["left", "center"].forEach((alignment) => {
      this.element
        .querySelector(`.align-${alignment}-button`)
        .addEventListener("click", () => {
          console.log("align: ", alignment);
          this.props.typeTester.textEditor.setAlignment(alignment);
          this.props.typeTester.element.dispatchEvent(
            new CustomEvent("interaction")
          );
        });
    });
  }
}
