import { h } from "common/dom-builder";
import { smallIconButton } from "common/icon-buttons";
import { preferences } from "../preferences";
import "common/components/custom-range-slider";

export class FontSpacingSelector {
  parent;
  element;

  constructor(props) {
    this.element = props.element ?? props.panel?.panelElement;
    this.props = props;
    this.init();
  }

  static buildElementForHeader(typeTester) {
    if (!typeTester.isFormattable) return;

    if (preferences.showSpacingPanelInHeader) {
      const element = h(
        "div",
        {
          class: ["input-group", "spacing-input-group", "in-header"],
          "data-input-group": "spacing",
        },
        []
      );
      new FontSpacingSelector({ typeTester, element });
      return element;
    } else {
      return h(
        "div",
        {
          class: [
            "input-group",
            "spacing-input-group",
            "expandable",
            // "expand-with-pushdown", // feature panel is the only one pushing down
          ],
          "data-input-group": "spacing",
        },
        [
          h("span", { class: "label" }, "spacing"),
          h("span", { class: "icon-button expand-button" }),
        ]
      );
    }
  }

  init() {
    const { format } = this.props.typeTester.props;
    this.currentLineHeight = format.lineHeight ?? 120;

    this.currentLetterSpacing = 0;

    if (preferences.spacingPanelCoversLabel) {
      this.props.panel?.containerElement?.classList?.add("covers-label");
    }
    this.render();
  }

  render() {
    this.element.appendChild(
      h("div", { class: "items" }, [
        this.buildLetterSpacingItem(),
        this.buildLineHeightItem(),
      ])
    );
  }

  buildLetterSpacingItem() {
    // -0.12em - 0.25em
    return h("div", { class: "item with-icon-in-front" }, [
      smallIconButton("spacing-horizontal"),
      this.buildInputElement({
        min: -0.12,
        max: 0.25,
        step: 0.001,
        value: this.currentLetterSpacing,
        onChange: (value) => {
          this.props.typeTester.textEditor.setLetterSpacing(value);
        },
      }),
    ]);
  }

  buildLineHeightItem() {
    // 90% to 200%
    return h("div", { class: "item with-icon-in-front" }, [
      smallIconButton("spacing-vertical"),
      this.buildInputElement({
        min: 90,
        max: 200,
        step: 1,
        value: this.currentLineHeight,
        onChange: (value) => {
          this.props.typeTester.textEditor.setLineHeight(value / 100);
        },
      }),
    ]);
  }

  buildInputElement({ min, max, step, value, onChange }) {
    const inputElement = h("custom-range-slider", {
      min,
      max,
      step,
      value,
    });
    inputElement.addEventListener("input", (ev) => {
      onChange(ev.target.value);
    });
    inputElement.addEventListener("change", (ev) => {
      onChange(ev.target.value);
      this.props.typeTester.element.dispatchEvent(
        new CustomEvent("interaction")
      );
    });

    this.props.typeTester.addEventListener("reset", () => {
      inputElement.value = value;
    });
    return inputElement;
  }
}
