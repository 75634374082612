import $ from 'jquery';

$('.license__agreement a').click(function() {
  if ($('.license__agreement pre').is(':hidden')) {
    $('.license__agreement pre').slideDown();
    $('.license__agreement').addClass('open');
  } else {
    $('.license__agreement').removeClass('open');
    $('.license__agreement pre').slideUp();
  }
});

