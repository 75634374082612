export function addRemoveClass(element, className, add) {
  if (add) {
    element.classList.add(className);
  } else {
    element.classList.remove(className);
  }
}

export function onHoverStateChange(element, callback) {
  element.addEventListener("mouseenter", () => {
    callback(true);
  });

  element.addEventListener("mouseleave", () => {
    callback(false);
  });
}
