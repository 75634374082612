import $ from "jquery";

$(".font_families.show").ready(function () {
  $('.font_style_sample input[type="range"]').on("input change", function () {
    var value = $(this).val();
    $(this)
      .parent()
      .siblings(".rendered_text")
      .css("font-size", value + "px");
  });

  $(window).scroll(function () {
    if ($(".licensing").length > 0) {
      var top = $(".licensing")[0].getBoundingClientRect().top;
      var $buyButton = $(".header-bottom .buy-button");
      if (top < 90) {
        $buyButton.css("opacity", 0);
      } else {
        $buyButton.css("opacity", 100);
      }
    }
  });
});
