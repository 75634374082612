import { addRemoveClass } from "common/dom-utils";
import { transitionTimeoutWithExtra } from "../TypeTester";

/**
 * Manages the show/hide state of a single popup, with debouncing, so that we if we move the mouse
 * from the label to the popup quickly, the popup does not flicker. This is only necessary if we're
 * using hover as a trigger for the popup.
 *
 * It also makes sure that there is only one popup visible at a time.
 */
export class PopupVisibilityState {
  element;
  timeout = null;
  name = null; // for debugging

  showTimeout = 10; // milliseconds
  hideTimeout = 100; // milliseconds

  isVisible = false;

  constructor(element, name, options, callback) {
    this.element = element;
    this.name = name;
    this.options = options;
    this.callback = callback;
  }

  get group() {
    return (this._group ??= this.options.exclusiveGroup ?? {});
  }

  toggle(immediately = false) {
    this.log("toggle()");
    if (this.isVisible) {
      this.hide(immediately);
    } else {
      this.show(immediately);
    }
  }

  async showHide(show) {
    this.log("showHide()", show);
    if (show) {
      await this.show();
    } else {
      await this.hide();
    }
  }

  show(immediately = false) {
    this.log("show()");
    return new Promise((resolve) => {
      clearTimeout(this.timeout);
      if (this.isVisible) {
        return;
      }
      const fn = () => {
        this.log(
          "showing now",
          this.group.visiblePopup
            ? ` - there was a visible popup: ${this.group.visiblePopup.name}`
            : ` - no visible popup`,
          this.group.visiblePopup === this ? "(SAME)" : "(DIFFERENT)"
        );
        if (this.group.visiblePopup && this.group.visiblePopup !== this) {
          this.log("hiding other popup");
          this.group.visiblePopup?.hide(true, {
            hidingBecauseOfOtherPopup: true,
          });
        }
        this.log("visiblePopup set");
        this.group.visiblePopup = this;

        this.setState({ display: true, visible: false });
        setTimeout(() => {
          this.setState({ display: true, visible: true });
          setTimeout(() => {
            resolve();
          }, transitionTimeoutWithExtra);
        }, 1);

        this.isVisible = true;
        this.callback(true, {});
      };
      if (immediately) {
        return fn();
      }
      this.timeout = setTimeout(fn, this.showTimeout);
    });
  }

  hide(immediately = false, options = {}) {
    this.log("hide()");
    return new Promise((resolve) => {
      clearTimeout(this.timeout);
      if (!this.isVisible) {
        return;
      }
      const fn = () => {
        this.log("hiding now");
        this.setState({ display: true, visible: false });

        this.log("visiblePopup clear");
        this.group.visiblePopup = null;
        this.isVisible = false;
        this.callback(false, options);

        setTimeout(() => {
          if (!this.isVisible) {
            this.setState({ display: false, visible: false });
          }
          resolve();
        }, transitionTimeoutWithExtra);
      };
      if (immediately) {
        return fn();
      }
      this.timeout = setTimeout(fn, this.hideTimeout);
    });
  }

  setState({ display, visible }) {
    addRemoveClass(this.element, "display", display);
    addRemoveClass(this.element, "visible", visible);
  }

  log(...args) {
    // console.log(`PopupVisibilityState ${this.name}:`, ...args);
  }
}
